import React from "react";
import {withRouter} from "react-router-dom";
import {ClientService} from "../../services/ClientService";
import Loader from "../../utils/Loader";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  AlertTitle,
  Card,
  CardHeader, Checkbox,
  FormControlLabel,
  Grid, ListItemText,
  MenuItem, OutlinedInput,
  Select,
  Switch,
  TextField,
  Tooltip
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MaterialTable from "material-table";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import CardContent from "@mui/material/CardContent";
import {LICENSE_TYPE, MESSAGES} from "../../utils/Messages";
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import BusinessIcon from "@material-ui/icons/Business";
import Moment from 'moment';
import ContactEmailIcon from '@material-ui/icons/ContactMail';
import {ExperiencesService} from "../../services/ExperiencesService";


class ClientList extends React.Component {

  clientService = new ClientService();
  experiencesService = new ExperiencesService();

  constructor(props) {
    super(props);
    this.state = {
      actionError: null,
      clients: null,
      isModalOpen: false,
      isLoading: false,
      isLoadingModal: false,
      currentClient: null,
      experiences: []
    };
  }

  componentDidMount() {
    this.clientService.getClients().then(response => {
      this.setState({
        clients: response
      })
    }).catch(err => {
      console.log("Errrr", err);
    });
  }

  columns = [
    {
      title: 'Nombre',
      field: 'name',
    },
    {
      title: 'Nit',
      field: 'nit',
    },
    {
      title: 'License Type',
      field: 'license.type',
      render: rowData => <Tooltip title={this.getLicenseToolTip(rowData)}>
        <div>{LICENSE_TYPE[rowData.license.type]}</div>
      </Tooltip>
    }
  ];


  ITEM_HEIGHT = 48;
  ITEM_PADDING_TOP = 8;
  menuProps = {
    PaperProps: {
      style: {
        maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
        width: 500,
      },
    },
  };

  getLicenseToolTip = (data) => {
    const license = data.license;
    let formattedDate = "No expira";
    if (license.expiringDate) {
      formattedDate = Moment(license.expiringDate).format('DD/MM/YYYY')
    }
    return `Usuarios: ${license.type === 'USER_LIMIT' ? license.size : 'Ilimitado'} | Streaming: ${license.streaming} | Fecha Expiración: ${formattedDate} | Domain: ${license.domain ? license.domain : 'Por lista de usuarios'}`
  }
  handleClose = () => {
    this.setState({
      isModalOpen: false,
      error: null,
    })
  };

  handleCloseError = () => {
    this.setState({
      error: undefined
    })
  }

  handleSaveClient = () => {
    this.setState({
      isLoadingModal: true
    }, () => {
      this.clientService.saveClient(this.state.currentClient).then(response => {
        this.setState({
          clients: response,
          isModalOpen: false,
          isLoadingModal: false,
          error: null,
        })
      }).catch(error => {
        this.setState({
          error: MESSAGES[error.message],
        })
      })
    })
  }

  handleClickOpen = () => {
    this.setState({
      isLoadingModal: true
    });
    if (!this.state.experiences || this.state.experiences.length === 0) {
      this.experiencesService.getExperiences().then(response => {
        this.setState({
          isLoadingModal: false,
          experiences: response
        })
      }).catch(err => {
        this.setState({
          isLoadingModal: false
        });
        console.log("Errrr", err);
      });
    }


    this.setState({
      isModalOpen: true
    })
  };

  handleRemoveClient = (client) => {
    this.setState({
      isLoading: true
    }, () => {
      this.clientService.removeClient(client).then(response => {
        this.setState({
          clients: response,
          isModalOpen: false,
          isLoading: false,
          error: null,
        })
      }).catch(error => {
        this.setState({
          isLoading: false,
          actionError: MESSAGES[error.message],
        })
      })
    })
  }

  handleNewClient = () => {
    this.setState({
      currentClient: {
        name: '',
        nit: '',
        license: {
          type: 'USER_LIMIT',
          size: '',
          streaming: false,
          expiringDate: null,
          domain: '',
          enabledExperiences :[]
        },
      }
    })

    this.handleClickOpen();
  }

  handleEditText = (event) => {
    const updatedClient = Object.assign({}, this.state.currentClient);
    updatedClient[event.target.name] = event.target.value;
    this.setState({
      currentClient: updatedClient
    });
  }

  handleEditLicense = (event) => {
    this.setState(prevState =>{
      const updatedClient = Object.assign({}, prevState.currentClient);
      updatedClient.license[event.target.name] = event.target.value;
      return {currentClient: updatedClient}
    });
  }

  handleEditExpiringDate = (jsDate) => {
    const updatedClient = Object.assign({}, this.state.currentClient);
    updatedClient.license.expiringDate = jsDate.format();
    this.setState({
      currentClient: updatedClient
    });
  }

  handleEditType = (event) => {

    const updatedClient = Object.assign({}, this.state.currentClient);
    updatedClient.license.type = event.target.value;
    this.setState({
      currentClient: updatedClient
    });
  }

  handleChangeStreaming = (event) => {
    const updatedClient = Object.assign({}, this.state.currentClient);
    updatedClient.license.streaming = event.target.checked;
    this.setState({
      currentClient: updatedClient
    });
  }

  handleChangeExperiences = (event) => {
    const {
      target: {value},
    } = event;

    this.setState(prevState=>{
      const updatedClient = Object.assign({}, prevState.currentClient);
      updatedClient.license[event.target.name] = event.target.value;
      updatedClient.license.enabledExperiences = typeof value === 'string' ? value.split(',') : value
      return {currentClient: updatedClient}
    })
  };

  handleCloseActionError = () =>{
    this.setState({
      actionError: undefined
    })
  }

  render() {

    const {history} = this.props;

    if (!this.state.clients || this.state.isLoading) return <Loader/>
    return <section className="Home">
      <Dialog open={this.state.isModalOpen} onClose={this.handleClose} fullWidth="lg" maxWidth="lg">
        {this.state.isLoadingModal ? <Loader/> : <>
          <DialogTitle>Crear o Editar</DialogTitle>
          {this.state.error ?
            <Alert severity="error" onClose={this.handleCloseError}>
              <AlertTitle>Error</AlertTitle>
              {this.state.error} <strong>Corrige antes de guardar!</strong>
            </Alert>
            : null}

          <DialogContent>
            <DialogContentText>
              Digite los datos del cliente
            </DialogContentText>
            {this.state.currentClient && <>
              <FormControl fullWidth sx={{m: 1}} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">Nombre</InputLabel>
                <Input
                  value={this.state.currentClient.name}
                  onChange={this.handleEditText}
                  name="name"
                />
              </FormControl>
              <FormControl fullWidth sx={{m: 1}} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">NIT</InputLabel>
                <Input
                  value={this.state.currentClient.nit}
                  onChange={this.handleEditText}
                  name="nit"
                />
              </FormControl>
              <Card style={{padding: '10px', background: '#ececea'}}>
                <CardHeader
                  title="Licencia"
                  subheader="Selecciona el tipo de licencia que aplica al cliente"
                />
                <CardContent>
                  <Grid container>
                    <Grid xs={12} md={6} item={true}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                        <Select
                          name="type"
                          value={this.state.currentClient.license.type}
                          label="Tipo"
                          onChange={this.handleEditType}
                        >
                          <MenuItem value={'USER_LIMIT'}>{LICENSE_TYPE.USER_LIMIT}</MenuItem>
                          <MenuItem value={'USER_UNLIMITED'}>{LICENSE_TYPE.USER_UNLIMITED}</MenuItem>

                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={6} item={true}>
                      {this.state.currentClient.license.type === 'USER_LIMIT' ?
                        <FormControl fullWidth sx={{m: 1}} variant="standard">
                          <InputLabel htmlFor="standard-adornment-amount">Cantidad de usuarios</InputLabel>
                          <Input
                            value={this.state.currentClient.license.size}
                            onChange={this.handleEditLicense}
                            name="size"
                            type="number"
                          />
                        </FormControl> :
                        <Alert severity="info">No se limitará la cantidad de usuarios que pueden usar la app</Alert>
                      }
                    </Grid>
                    <Grid xs={12} md={6} item={true}>
                      <FormControl fullWidth sx={{m: 1}} variant="standard">
                        <InputLabel htmlFor="standard-adornment-amount">Dominio</InputLabel>
                        <Input
                          value={this.state.currentClient.license.domain}
                          onChange={this.handleEditLicense}
                          name="domain"
                        />
                      </FormControl>

                    </Grid>
                    <Grid xs={12} md={6} item={true}>
                      <Alert
                        severity="info">{this.state.currentClient.license.domain ? "Sólo podran ingresar usuario con este dominio (Ej. ejemplo.com)" : "Sin dominio podrás configurar una lista con los usuarios que desees"}</Alert>
                    </Grid>
                    <Grid xs={12} md={6} item={true}>
                      <FormControlLabel control={<Switch checked={this.state.currentClient.license.streaming}
                                                         onChange={this.handleChangeStreaming}/>}
                                        label="Habilitar Streaming"/>
                    </Grid>

                    <Grid xs={12} md={6} item={true}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          label="Expiración de licencia"
                          inputFormat="DD/MM/YYYY"
                          value={this.state.currentClient.license.expiringDate}
                          onChange={this.handleEditExpiringDate}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid xs={12} md={12} item={true}>
                      <FormControl sx={{m: 1, width: 300}}>
                        <InputLabel id="demo-multiple-checkbox-label">Experiencias habilitadas</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={this.state.currentClient.license.enabledExperiences}
                          onChange={this.handleChangeExperiences}
                          input={<OutlinedInput label="Experiencias habilitadas"/>}
                          renderValue={(selected) => {
                            return this.state.experiences.filter(item=> selected.includes(item.id)).map(item=>item.name).join(', ')
                          }}
                          MenuProps={this.menuProps}
                        >
                          {this.state.experiences.map((experience) => (
                            <MenuItem key={experience.id} value={experience.id}>
                              <Checkbox checked={this.state.currentClient.license.enabledExperiences.indexOf(experience.id) > -1}/>
                              <ListItemText primary={experience.name}/>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                </CardContent>
              </Card>

            </>}

          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleClose}>Cancelar</Button>
            <Button onClick={this.handleSaveClient}>Guardar</Button>
          </DialogActions>
        </>}

      </Dialog>

      <div className="m-1">
        <div className="container-fluid col-12 col-md-11 table-responsive-md overflow-auto">
          <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained" onClick={() => {
            history.push('/login')
          }}>
            <ArrowBackIcon/>
            Atras
          </Button>
          <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained" onClick={this.handleNewClient}>
            Crear Cliente.
            <BusinessIcon/>

          </Button>
          {this.state.actionError ?
            <Alert severity="error" onClose={this.handleCloseActionError}>
              <AlertTitle>Error</AlertTitle>
              {this.state.actionError} <strong>Revísalo e intenta de nuevo!</strong>
            </Alert>
            : null}

          <div className="m-2">
            <MaterialTable
              columns={this.columns}
              data={this.state.clients}
              title="Clientes"
              actions={[
                {
                  icon: 'edit',
                  tooltip: 'Editar',
                  onClick: (event, data) => {
                    if(!data.license.enabledExperiences){
                      data.license.enabledExperiences=[];
                    }
                    this.setState({
                      currentClient: data
                    })
                    this.handleClickOpen()
                  }
                },
                {
                  icon: ContactEmailIcon,
                  tooltip: 'Lista de Usuarios',
                  onClick: (event, data) => {
                    if (data.license.domain) {
                      alert('No puedes administrar lista dado que la licencia de este cliente es por dominio');
                    } else {
                      this.setState({
                        isModalUsersOpened: true
                      })
                    }
                  }
                },

                {
                  icon: 'delete',
                  tooltip: 'Eliminar',
                  onClick: (event, data) => {
                    this.handleRemoveClient(data);
                  }
                }
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 5
              }}
              localization={{
                header: {
                  actions: "Acciones"
                },
                toolbar: {
                  searchPlaceholder: "Buscar"
                },
                pagination: {
                  labelRowsSelect: "filas",
                  labelDisplayedRows: "{from}-{to} de {count}"
                },
                body: {
                  emptyDataSourceMessage: "No has creado ningun cliente"
                }
              }}
            />
          </div>
        </div>
      </div>
    </section>
  }
}

export default withRouter(ClientList)