import React from "react";
import {withRouter} from "react-router-dom";
import MaterialTable from "material-table";
import {LicensedUsersService} from "../../services/LicensedUsersService";
import {MESSAGES} from "../../utils/Messages";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MobileIcon from "@material-ui/icons/MobileFriendly";

import {Alert, AlertTitle, Card, CardActions, CardContent, Step, StepLabel, Stepper} from "@mui/material";
import Loader from "../../utils/Loader";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {CSVLink} from "react-csv";
import Papa from "papaparse";

class LicensedUsers extends React.Component {

  licensedUsersService = new LicensedUsersService();
  fileRef = null;
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      activeStep: 0,
      usersToUpload: [],
      isLoading: false,
      isModalOpen:false,
      isLoadingModal: false,
      error: null,
      actionError:null
    };
    this.fileRef = React.createRef();

  }

  componentDidMount() {
    this.setState({
      isLoading: true
    })
    this.licensedUsersService.getLicensedUsers().then(response => {
      this.setState({
        users: response,
        isLoading: false
      })
    }).catch(err => {
      this.setState({
        isLoading: false
      })
    });
  }



  steps = [
    'Prepara',
    'Configura',
    'Confirma',
  ];

  data = [
    ["email", "category1", "category2", "category3", "category4", "category5"],
    ["tuusuario@tuorganizacion.com", "Estudiante", "Sede A", "Jornada Mañana", "Once", "Once-1", ""]
  ];

  handleRemoveLicensed = (user) => {
    this.setState({
      isLoading: true
    }, () => {
      this.licensedUsersService.removeLicensedUser(user).then(response => {
        this.setState({
          users: response,
          isLoading: false,
          error: null,
        }, () => {
          window.location.reload();
        })
      }).catch(error => {
        this.setState({
          isLoading: false,
          actionError: MESSAGES[error.message],
        })
      })
    })
  }
  handleClickOpen = () => {
    this.setState({
      isModalOpen: true
    })
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false,
      error: null,
    })
  };

  handleGoToUpload = () => {
    this.setState({
      activeStep: 1,
    })
  }

  handleGoToConfirm = (event) => {

    const files = event.target.files;

    if (files) {
      console.log(files[0]);
      Papa.parse(files[0], {
          complete: (results) => {


            console.log("Finished:", results.data);
            const finalData = [];
            results.data.forEach((item, index) => {
              if (index !== 0) {
                const newUser = {}
                item.forEach((userProp, indexUser) => {
                  if (userProp) {
                    newUser[results.data[0][indexUser]] = userProp
                  }
                })

                finalData.push(newUser);
              }
            });

            console.log(finalData);
            this.fileRef.current.value = null;
            this.setState({
              activeStep: 2,
              usersToUpload: finalData
            })

          }
        }
      )
    }

  }

  handleUpload = () => {
    this.setState({
      isLoadingModal: true
    });
    this.licensedUsersService.addLicensedUsers(this.state.usersToUpload).then(response => {
      window.location.reload();
    }).catch(error => {
      this.setState({
        activeStep: 1,
        usersToUpload: [],
        isLoadingModal: false,
        error: MESSAGES[error.message]
      })
    })
  }

  render() {
    const columnsModal = [
      {
        title: 'Email',
        field: 'email',
      },
      {
        title: 'Categoria 1',
        field: 'category1',
      },
      {
        title: 'Categoria 2',
        field: 'category2',
      },
      {
        title: 'Categoria 3',
        field: 'category3',
      },
      {
        title: 'Categoria 4',
        field: 'category4',
      },
      {
        title: 'Categoria 5',
        field: 'category5',
      }

    ];

    if (this.state.isLoading) return <Loader/>
    const {history} = this.props;
    return <section className="Home">
      <Dialog open={this.state.isModalOpen} onClose={this.handleClose} fullWidth="lg" maxWidth="lg">
        {this.state.isLoadingModal ? <Loader/> : <>
          <DialogTitle>Cargar usuarios</DialogTitle>
          {this.state.error ?
            <Alert severity="error" onClose={this.handleCloseError}>
              <AlertTitle>Error</AlertTitle>
              {this.state.error} <strong>Corrige antes de guardar!</strong>
            </Alert>
            : null}

          <DialogContent>
            <DialogContentText>
              En esta sección debes cargar un archivo CSV (descarga archivo ejemplo)
            </DialogContentText>
            <Stepper activeStep={this.state.activeStep} alternativeLabel>
              {this.steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {
              this.state.activeStep === 0 ?
                <div className="col d-flex justify-content-center">
                  <Card>
                    <CardContent>
                      En el siguiente botón puedes descargar un archivo <br/>
                      CSV con la estructura de ejemplo para subir los usuarios <br/>
                      autorizados a usar Nova360+ (Max 200 usuarios por subida)<br/>
                      <CSVLink className="btn btn-green m-3 pr-4 pl-4" data={this.data}>
                        Click para descargar ejemplo
                      </CSVLink>
                    </CardContent>
                    <CardActions>
                      <Button variant="text" onClick={this.handleGoToUpload}>Continuar</Button>
                    </CardActions>
                  </Card>
                </div>
                : null
            }
            {
              this.state.activeStep === 1 ?
                <div className="col d-flex justify-content-center">
                  <Card>
                    <CardContent>
                      Luego de diligenciar el archivo, has click en el siguiente<br/>
                      botón para iniciar el proceso<br/>
                      autorizados a usar Nova360+<br/>
                      <Button variant="contained" className="btn btn-green m-3 pr-4 pl-4" component="label">
                        Subir archivo
                        <input hidden
                               ref={this.fileRef}
                               type="file"
                               accept=".csv"
                               onChange={this.handleGoToConfirm}
                        />
                      </Button>

                    </CardContent>
                    <CardActions>
                      <Button variant="text" onClick={this.handleGoToConfirm}>Continuar a confirmar</Button>
                    </CardActions>
                  </Card>
                </div> : null
            }
            {

              this.state.activeStep === 2 ?
                <>
                  <Button className="btn btn-green m-3 pr-4 pl-4" onClick={this.handleUpload}>Confirmar y subir</Button>
                  <MaterialTable
                    columns={columnsModal}
                    data={this.state.usersToUpload}
                    title="Usuarios a cargar"
                    options={{
                      actionsColumnIndex: -1,
                      pageSize: 5
                    }}
                    localization={{
                      toolbar: {
                        searchPlaceholder: "Buscar"
                      },
                      pagination: {
                        labelRowsSelect: "filas",
                        labelDisplayedRows: "{from}-{to} de {count}"
                      },
                      body: {
                        emptyDataSourceMessage: "No se encontraron usuario para subir"
                      }
                    }}
                  />

                </>
                : null
            }

          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleClose}>Cancelar</Button>
          </DialogActions>
        </>}

      </Dialog>
      <div className="m-1">
        <div className="container-fluid col-12 col-md-11 table-responsive-md overflow-auto">
          <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained" onClick={() => {
            history.push('/login')
          }}>
            <ArrowBackIcon/>
            Atras
          </Button>
          <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained" onClick={this.handleClickOpen}>
            Cargar Usuarios.
            <MobileIcon/>

          </Button>
          {this.state.actionError ?
            <Alert severity="error" onClose={this.handleCloseActionError}>
              <AlertTitle>Error</AlertTitle>
              {this.state.actionError} <strong>Revísalo e intenta de nuevo!</strong>
            </Alert>
            : null}
          <div className="m-2">
            <MaterialTable
              columns={columnsModal}
              data={this.state.users}
              title="Usuarios de la aplicación"
              actions={[
                {
                  icon: 'delete',
                  tooltip: 'Eliminar',
                  onClick: (event, data) => {
                    this.handleRemoveLicensed(data);
                  }
                }
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 5
              }}
              localization={{
                header: {
                  actions: "Acciones"
                },
                toolbar: {
                  searchPlaceholder: "Buscar"
                },
                pagination: {
                  labelRowsSelect: "filas",
                  labelDisplayedRows: "{from}-{to} de {count}"
                },
                body: {
                  emptyDataSourceMessage: "No hay usuarios asociados"
                }
              }}
            />
          </div>
        </div>
      </div>

    </section>

  }
}

export default withRouter(LicensedUsers)