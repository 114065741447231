import PubSub from 'pubsub-js';

export class GenericService {


    getUser = ()=>{
        return JSON.parse(localStorage.getItem("user")) || null
    }

    getHeaders = () => {
        if(this.getUser()!=null) {

            return {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.getUser().token
            }

        }
        return {};
    }

    customResolve = async (resolve, reject, response) => {
        const body = await response.json()
        if (response.status === 200) {
            resolve(body)
        } else {
            reject(body)
        }
    }


    consumeService = async(url, method, body) =>{
        return new Promise(async(resolve, reject) => {
            const config = {
                method: method,
                headers: this.getHeaders(),
            }
            if(body){
                config.body = JSON.stringify(body);
            }
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${url}`,
                  config
                );
                if (response.ok) {
                    await this.customResolve(resolve, reject, response)
                } else {
                    if(response.status){
                        const body = await response.json()
                        reject({
                            status: response.status,
                            message: body.code
                        });
                    } else {
                        PubSub.publish('logout', 'Service failed with 401');
                        reject({status: 401});
                    }
                }
            } catch(error) {
                PubSub.publish('logout', 'Service failed with 401');
                reject({status: 401});
            }
        });
    }

}