import {GenericService} from "./GenericService";

export class UserGroupsService extends GenericService{

  getUserGroups = () => {
    return this.consumeService(`/license/user-groups/admin/list`, 'GET');
  }
  saveUserGroup = (userGroup) =>{
    return this.consumeService('/license/user-groups/admin/save', 'POST', userGroup);
  }

  removeUserGroup = (userGroup) =>{
    return this.consumeService('/license/user-groups/admin/remove', 'DELETE', userGroup);
  }


  removeUserFromGroup = (params) =>{
    return this.consumeService('/license/user-groups/admin/remove-user', 'DELETE', params);
  }

  addUserToGroup = (params) =>{
    return this.consumeService('/license/user-groups/admin/add-user', 'POST', params);
  }

}