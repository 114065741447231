import {withRouter} from 'react-router-dom'
import MaterialTable from 'material-table'
import React from "react";
import {ExperiencesService} from "../../services/ExperiencesService";
import Loader from "../../utils/Loader";
import DialogTitle from "@mui/material/DialogTitle";
import {Alert, AlertTitle} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import {MESSAGES} from "../../utils/Messages";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InputAdornment from "@mui/material/InputAdornment";
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import TextField from "@mui/material/TextField";

class ListExperiences extends React.Component {

    experiencesService = new ExperiencesService();

    constructor(props) {
        super(props);
        this.state = {
            actionError: null,
            experiences: null,
            isModalOpen: false,
            isLoading: false,
            isLoadingModal: false,
            currentExperience: null
        };
    }

    componentDidMount() {
       this.experiencesService.getExperiences().then(response => {
            this.setState({
                experiences: response
            })
        }).catch(err=> {
            console.log("Errrr", err);
        });
    }

    columns = [
        {
            title: 'ID',
            field: 'id',
        },
        {
            title: 'Nombre',
            field: 'name',
        },
        {
            title: 'Categoria',
            field: 'category',
        },
        {
            title: 'Versión',
            field: 'version',
        }
    ];

    handleClose = () => {
        this.setState({
            isModalOpen: false,
            error: null,
        })
    };


    handleCloseError = () =>{
        this.setState({
            error: undefined
        })
    }
    handleSaveExperience = ()=>{
        this.setState({
            isLoadingModal: true
        }, ()=>{
            this.experiencesService.saveExperience(this.state.currentExperience).then(response => {
                console.log(response);
                this.setState({
                    experiences:response,
                    isModalOpen: false,
                    isLoadingModal: false,
                    error: null,
                })
            }).catch(error => {
                this.setState({
                    error: MESSAGES[error.message],
                })
            })
        })
    }

    handleRemoveExperience = (experience) =>{
        this.setState({
            isLoading: true
        }, ()=>{
            this.experiencesService.removeExperience(experience).then(response => {
                console.log(response);
                this.setState({
                    experiences:response,
                    isModalOpen: false,
                    isLoading: false,
                    error: null,
                })
            }).catch(error => {
                this.setState({
                    isLoading: false,
                    actionError: MESSAGES[error.message],
                })
            })
        })
    }

    handleNewExperience = () => {
        this.setState({
            currentExperience: {
                id: "",
                name:"",
                category:"",
                version: "0.0.1",
            }
        })

        this.handleClickOpen();
    }

    handleClickOpen = () => {
        this.setState({
            isModalOpen: true
        })
    };

    handleEditText = (event)=>{
        const updatedExperience = Object.assign({}, this.state.currentExperience);
        updatedExperience[event.target.name] = event.target.value;
        this.setState({
            currentExperience: updatedExperience
        });
    }

    handleUpgradeVersion = (location) =>{
        const updatedExperience = Object.assign({}, this.state.currentExperience);

        if(!updatedExperience.version) {
            updatedExperience.version='0.0.0';
        }
        const versionSplit = updatedExperience.version.split('.');
        versionSplit[location]=parseInt(versionSplit[location])+1;
        updatedExperience.version = versionSplit.join('.');
        this.setState({
            currentExperience: updatedExperience
        });
    }

    isDisabledId = (id)=>{
        return this.state.experiences.filter(item=>item.id===id).length>0
    }

    handleCloseActionError = () =>{
        this.setState({
            actionError: undefined
        })
    }

    render(){

        const { history } = this.props;

        if (!this.state.experiences || this.state.isLoading) return <Loader/>
        return <section className="Home">
            <Dialog open={this.state.isModalOpen} onClose={this.handleClose} fullWidth="lg" maxWidth="lg">
                {this.state.isLoadingModal?<Loader/>:<>
                    <DialogTitle>Crear o Editar</DialogTitle>
                    {this.state.error?
                      <Alert severity="error" onClose={this.handleCloseError}>
                          <AlertTitle>Error</AlertTitle>
                          {this.state.error} <strong>Corrige antes de guardar!</strong>
                      </Alert>
                      :null}

                    <DialogContent>
                        <DialogContentText>
                            Digite los datos de la experience
                        </DialogContentText>
                        {this.state.currentExperience && <>
                            <FormControl fullWidth sx={{m: 1}} variant="standard">
                                <InputLabel htmlFor="standard-adornment-amount">ID</InputLabel>
                                <Input
                                  value={this.state.currentExperience.id}
                                  disabled={this.isDisabledId(this.state.currentExperience.id)}
                                  onChange={this.handleEditText}
                                  name="id"
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{m: 1}} variant="standard">
                                <InputLabel htmlFor="standard-adornment-amount">Nombre</InputLabel>
                                <Input
                                  value={this.state.currentExperience.name}
                                  onChange={this.handleEditText}
                                  name="name"
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{m: 1}} variant="standard">
                                <InputLabel htmlFor="standard-adornment-amount">Categoría</InputLabel>
                                <Input
                                  value={this.state.currentExperience.category}
                                  onChange={this.handleEditText}
                                  name="category"
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{m: 1}} variant="standard">
                                <TextField
                                  id="input-with-icon-textfield"
                                  onChange={this.handleEditText}
                                  name="version"
                                  disabled={true}
                                  InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                            <AddCircleOutlineIcon color="error" onClick={() => {
                                                this.handleUpgradeVersion(0);
                                            }}/>
                                            <AddCircleOutlineIcon color="secondary" onClick={() => {
                                                this.handleUpgradeVersion(1);
                                            }}/>
                                            <AddCircleOutlineIcon color="primary" onClick={() => {
                                                this.handleUpgradeVersion(2);
                                            }}/>
                                        </InputAdornment>
                                      )
                                  }}
                                  variant="standard"
                                  value={this.state.currentExperience.version}
                                />

                            </FormControl>
                        </>}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleClose}>Cancelar</Button>
                        <Button onClick={this.handleSaveExperience}>Guardar</Button>
                    </DialogActions>
                </>}

            </Dialog>

            <div className="m-1">
                <div className="container-fluid col-12 col-md-11 table-responsive-md overflow-auto">
                    <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained"  onClick={()=>{
                        history.push('/login')
                    }}>
                        <ArrowBackIcon/>
                        Atras
                    </Button>
                    <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained"  onClick={this.handleNewExperience}>
                        Crear Experiencia.
                        <ThreeDRotationIcon/>
                    </Button>
                    {this.state.actionError?
                      <Alert severity="error" onClose={this.handleCloseActionError}>
                          <AlertTitle>Error</AlertTitle>
                          {this.state.actionError} <strong>Revísalo e intenta de nuevo!</strong>
                      </Alert>
                      :null}

                    <div className="m-2">
                            <MaterialTable
                              columns={this.columns}
                              data={this.state.experiences}
                              title="Experiencias"
                              actions={[
                                  {
                                      icon: 'edit',
                                      tooltip: 'Editar',
                                      onClick: (event, data) => {
                                          this.setState({
                                              currentExperience: data
                                          })
                                          this.handleClickOpen()
                                      }
                                  },
                                  {
                                      icon: 'work',
                                      tooltip: 'Actividades de Consolidación',
                                      onClick: (event, data) => {
                                          history.push(`/experiences/activities/${data.id}`);
                                      }
                                  },
                                  {
                                      icon: AttachFileIcon,
                                      tooltip: 'Recursos Complementarios',
                                      onClick: (event, data) => {
                                          history.push(`/experiences/additional-sources/${data.id}`);
                                      }
                                  },
                                  {
                                      icon: 'delete',
                                      tooltip: 'Eliminar',
                                      onClick: (event, data) => {
                                          this.handleRemoveExperience(data);
                                      }
                                  }
                              ]}
                              options={{
                                  actionsColumnIndex: -1,
                                  pageSize: 5
                              }}
                              localization={{
                                  header: {
                                      actions: "Acciones"
                                  },
                                  toolbar: {
                                      searchPlaceholder: "Buscar"
                                  },
                                  pagination: {
                                      labelRowsSelect: "filas",
                                      labelDisplayedRows: "{from}-{to} de {count}"
                                  },
                                  body: {
                                      emptyDataSourceMessage: "No has creado ninguna experiencia"
                                  }
                              }}
                            />
                    </div>
                </div>
            </div>
        </section >
    }
}

export default withRouter(ListExperiences);