import {GenericService} from "./GenericService";

export class ClientService extends GenericService{

  getClients = () => {
    return this.consumeService(`/license/clients/admin/list`, 'GET');
  }
  saveClient = (client) =>{
    return this.consumeService('/license/clients/admin/create', 'POST', client);
  }

  removeClient = (client) =>{
    return this.consumeService('/license/clients/admin/remove', 'DELETE', client);
  }

  activateExperiences = (experienceConfig) =>{
    return this.consumeService('/license/clients/admin/activate-experiences', 'POST', experienceConfig);
  }

}