import { createContext, useState, useEffect } from "react";
import axios from 'axios'

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem("user")) || null
    );
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        try {
            localStorage.setItem("user", JSON.stringify(user))
        } catch (error) {
            localStorage.removeItem("user");
            console.log(error)
        }

    }, [user])

    const contextValue = {
        user, loading, error,
        login(credenciales) {
            axios
                .post(process.env.REACT_APP_BACKEND_URL + '/security/admin/login', {
                    username: credenciales.username,
                    password: credenciales.password
                })
                .then((response) => {
                    if (response.data === "Not User found."){
                        alert("Usuario no encontrado")
                    }else if(response.data){
                        setUser(response.data);
                    }else{
                        alert("Contraseña incorrecta")
                    }                     
                })
                .catch((error) => {
                    setError(error);
                    console.log("ERROR: " + error)
                })
                .finally(() => {
                    setLoading(false);
                })
        },
        logout() {
            setUser(null);
        },
        isLogged() {
            return !!user;
        }
    }

    return <AuthContext.Provider value={contextValue}>
        {children}
    </AuthContext.Provider>
}

export default AuthProvider;