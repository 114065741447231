import React from "react";
import {withRouter} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import Loader from "../../utils/Loader";
import DialogTitle from "@mui/material/DialogTitle";
import {Alert, AlertTitle, Card, MenuItem, Select} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileSaver from 'file-saver';


import MaterialTable from "material-table";
import {AdditionalSourcesService} from "../../services/AdditionalSourcesService";
import {ADDITIONAL_SOURCE_TYPE, MESSAGES} from "../../utils/Messages";

import CardContent from '@mui/material/CardContent';

class ExperienceAdditionalSources extends React.Component {


  additionalSourcesService = new AdditionalSourcesService();
  experienceId = ""

  constructor(props) {
    super(props);

    this.state = {
      currentAdditionalSource: {},
      additionalSources: null,
      actionError: null,
      error: null,
      isModalOpen: false,
      currentFile: null,
    };
  }

  componentDidMount() {
    this.experienceId = this.props.computedMatch.params.experienceId;
    this.additionalSourcesService.getAdditionalSources(this.experienceId).then(response => {
      this.setState({
        additionalSources: response
      })
    }).catch(err => {
      console.log("Errrr", err);
    });
  }

  columns = [
    {
      title: 'Nombre',
      field: 'key',
    },
    {
      title: 'Experiencia',
      field: 'experienceId',
    },
    {
      title: 'Tipo',
      field: 'type',
      render : rowData => ADDITIONAL_SOURCE_TYPE[rowData.type]
    }
  ];

  handleClickOpen = () => {
    this.setState({
      isModalOpen: true
    })
  };

  handleRemoveAdditionalSource = (experience) => {
    this.setState({
      isLoading: true
    }, () => {
      this.additionalSourcesService.removeAdditionalSource(experience).then(response => {
        this.setState({
          additionalSources: response,
          isModalOpen: false,
          isLoading: false,
          error: null,
        })
      }).catch(error => {
        this.setState({
          isLoading: false,
          actionError: MESSAGES[error.message],
        })
      })
    })
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false,
      error: null,
    })
  };


  handleSaveAdditionalSource = () => {


    if(this.state.currentAdditionalSource.size<=(125000*10) || this.state.currentAdditionalSource.type==='LINK'){
      this.setState({
        isLoadingModal: true
      }, () => {
        this.additionalSourcesService.saveAdditionalSource(this.state.currentAdditionalSource).then(response => {

          if(response.uploadURL){
            fetch(response.uploadURL, {
              method: "PUT",
              headers: new Headers({'Content-Type': this.state.currentAdditionalSource.contentType}),
              body: this.state.currentFile
            }).then(fileResponse => {
              console.log(fileResponse);
              this.setState({
                additionalSources: response.allSources,
                isModalOpen: false,
                isLoadingModal: false,
                error: null,
              })
            })
          } else {
            this.setState({
              additionalSources: response.allSources,
              isModalOpen: false,
              isLoadingModal: false,
              error: null,
            })
          }
        }).catch(error => {
          this.setState({
            isLoadingModal: false,
            error: MESSAGES[error.message],
          })
        })
      })
    } else {
      this.setState({
        error: 'El tamaño del archivo debe ser máximo de 10MB',
      })
    }

  };

  handleEditType = (event) => {
    this.setState({
      currentAdditionalSource: {
        key: '',
        experienceId: this.experienceId,
        type: event.target.value,
        size: '',
        contentType: '',
        link: ''
      }
    })
  }

  handleNewExperience = () => {
    this.setState({
      currentAdditionalSource: {
        key: "",
        experienceId: this.experienceId,
        type: '',
        size: '',
        contentType: '',
        link: ''
      }
    })

    this.handleClickOpen();
  }

  handleEditText = (event)=>{
    const updatedAdditionalSource = Object.assign({}, this.state.currentAdditionalSource);
    updatedAdditionalSource[event.target.name] = event.target.value;
    this.setState({
      currentAdditionalSource: updatedAdditionalSource
    });
  }

  handleDownloadFile = (additionalSource)=>{
    this.additionalSourcesService.getAdditionalSourceFile(additionalSource.id).then(response=>{
      FileSaver.saveAs(
        response.getUrl,
        additionalSource.key);
    })
  }

  handleOpenLink = (additionalSource)=>{
    window.open(additionalSource.link,'_blank');
  }

  handleSelectFile = (event) => {
    const updatedAdditionalSource = Object.assign({}, this.state.currentAdditionalSource);
    updatedAdditionalSource.key = event.target.files[0].name;
    updatedAdditionalSource.size = event.target.files[0].size;
    updatedAdditionalSource.contentType = event.target.files[0].type;
    this.setState({
      currentAdditionalSource: updatedAdditionalSource,
      currentFile: event.target.files[0]
    });
  }

  render() {

    const {history} = this.props;
    if (!this.state.additionalSources || this.state.isLoading) return <Loader/>

    return <section className="Home">
      <Dialog open={this.state.isModalOpen} onClose={this.handleClose} fullWidth="lg" maxWidth="lg">
        {this.state.isLoadingModal ? <Loader/> : <>
          <DialogTitle>Crear o Editar</DialogTitle>
          {this.state.error ?
            <Alert severity="error" onClose={this.handleCloseError}>
              <AlertTitle>Error</AlertTitle>
              {this.state.error} <strong>Corrige antes de guardar!</strong>
            </Alert>
            : null}

          <DialogContent>
            <DialogContentText>
              Digite los datos del recurso adicional
            </DialogContentText>
            {this.state.currentAdditionalSource && <>

              <Card>
                <CardContent>
                  <Alert severity="info">Selecciona el tipo de recurso que deseas agregar!</Alert>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                    <Select
                      name="type"
                      value={this.state.currentAdditionalSource.type}
                      label="Tipo"
                      onChange={this.handleEditType}
                    >
                      <MenuItem value={'FILE'}>{ADDITIONAL_SOURCE_TYPE.FILE}</MenuItem>
                      <MenuItem value={'VIDEO'}>{ADDITIONAL_SOURCE_TYPE.VIDEO}</MenuItem>
                      <MenuItem value={'IMAGE'}>{ADDITIONAL_SOURCE_TYPE.IMAGE}</MenuItem>
                      <MenuItem value={'LINK'}>{ADDITIONAL_SOURCE_TYPE.LINK}</MenuItem>

                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
              <Card>
                {['FILE', 'VIDEO', 'IMAGE'].includes(this.state.currentAdditionalSource.type)?<CardContent>
                  <FormControl fullWidth sx={{m: 1}} variant="standard">
                    <Input
                      onChange={this.handleSelectFile}
                      type="file"
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{m: 1}} variant="standard">
                    <Input
                      disabled={true}
                      value={this.state.currentAdditionalSource.key}
                      name="key"
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{m: 1}} variant="standard">
                    <Input
                      disabled={true}
                      value={this.state.currentAdditionalSource.contentType}
                      name="contentType"
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{m: 1}} variant="standard">

                    <Input
                      disabled={true}
                      value={this.state.currentAdditionalSource.size}
                      name="contentType"
                    />
                  </FormControl>
                </CardContent>:<>{this.state.currentAdditionalSource.type==='LINK'?<CardContent>
                  <FormControl fullWidth sx={{m: 1}} variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">Nombre</InputLabel>
                    <Input
                      value={this.state.currentAdditionalSource.key}
                      name="key"
                      onChange={this.handleEditText}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{m: 1}} variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">Link</InputLabel>
                    <Input
                      value={this.state.currentAdditionalSource.link}
                      name="link"
                      onChange={this.handleEditText}
                    />
                  </FormControl>
                </CardContent>:null}</>}
              </Card>


            </>}
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleClose}>Cancelar</Button>
            <Button onClick={this.handleSaveAdditionalSource}>Guardar</Button>
          </DialogActions>
        </>}

      </Dialog>

      <div className="m-1">
        <div className="container-fluid col-12 col-md-11 table-responsive-md overflow-auto">
          <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained" onClick={() => {
            history.go(-1)
          }}>
            <ArrowBackIcon/>
            Atras
          </Button>
          <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained" onClick={this.handleNewExperience}>
            Agregar recurso.
            <AttachFileIcon/>
          </Button>

          <div className="m-2">
            <MaterialTable
              columns={this.columns}
              data={this.state.additionalSources}
              title="Recursos adicionales"
              actions={[
                rowData=>({
                  icon: ['FILE', 'VIDEO', 'IMAGE'].includes(rowData.type)?'download':'visibility',
                  tooltip: ['FILE', 'VIDEO', 'IMAGE'].includes(rowData.type)?'Descargar':'Ir',
                  onClick: (event, data) => {
                    if(['FILE', 'VIDEO', 'IMAGE'].includes(rowData.type)){
                      this.handleDownloadFile(data);
                    } else {
                      this.handleOpenLink(data);
                    }
                  }
                }),
                {
                  icon: 'delete',
                  tooltip: 'Eliminar',
                  onClick: (event, data) => {
                    this.handleRemoveAdditionalSource(data);
                  }
                },

              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 5
              }}
              localization={{
                header: {
                  actions: "Acciones"
                },
                toolbar: {
                  searchPlaceholder: "Buscar"
                },
                pagination: {
                  labelRowsSelect: "filas",
                  labelDisplayedRows: "{from}-{to} de {count}"
                },
                body: {
                  emptyDataSourceMessage: "No has creado ninguna experiencia"
                }
              }}
            />
          </div>
        </div>
      </div>
    </section>
  }
}

export default withRouter(ExperienceAdditionalSources);