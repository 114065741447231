import React from "react";
import {css} from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";

class Loader extends React.Component {
  override = css`
        display: block;
        margin: 4em auto;
    `;

  render() {
    return <PuffLoader color="#94b43b" loading={true} css={this.override} size={150}/>;
  }
}
export default Loader;
