import {GenericService} from "./GenericService";

export class AdditionalSourcesService extends GenericService{

  getAdditionalSources = (experienceId) => {
    return this.consumeService(`/experience/additional-sources/admin/get/${experienceId}`, 'GET');
  }
  saveAdditionalSource = (activity) =>{
    return this.consumeService('/experience/additional-sources/admin/create', 'POST', activity);
  }

  removeAdditionalSource = (activity) =>{
    return this.consumeService('/experience/additional-sources/admin/remove', 'DELETE', activity);
  }

  getAdditionalSourceFile = (id) => {
    return this.consumeService(`/experience/additional-sources/admin/get-file/${id}`, 'GET');
  }


}