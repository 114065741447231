import {createTheme} from "@mui/material/styles";
import {lightBlue} from "@mui/material/colors";

export const THEME = createTheme({
  palette: {
    primary: {
      main: '#94b43b'
    },
    secondary: lightBlue,
  }
})