import {Alert, AlertTitle, createTheme} from '@mui/material';
import MaterialTable from 'material-table'
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import WorkIcon from '@material-ui/icons/Work';

import UnarchiveIcon from '@material-ui/icons/Unarchive';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import InputAdornment from '@mui/material/InputAdornment';
import Container from '@mui/material/Container';
import {ActivitiesService} from "../../services/ActivitiesService";
import React from "react";
import {withRouter} from "react-router-dom";
import {MESSAGES, STATUS, TYPE_ACTIVITY} from "../../utils/Messages";
import {Badge} from "@material-ui/core";
import Loader from "../../utils/Loader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

class ListActivities extends React.Component {

    activitiesService = new ActivitiesService();
    experienceId = ""

    constructor(props) {
        super(props);
        this.state = {
            currentActivity: {},
            activities: null,
            actionError: null,
            error: null,
            isModalOpen: false
        };
    }

    componentDidMount() {

        this.experienceId = this.props.computedMatch.params.experienceId;
        this.activitiesService.getActivities(this.experienceId).then(response => {
            this.setState({
                activities: response
            })
        }).catch(err=> {
            localStorage.removeItem("user");
            this.props.history.push("/login");
        });

    }

    possibleOptions = 'abcdefghijk'
    defaultMaterialTheme = createTheme();

    columns = [
        {
            title: 'Enunciado',
            field: 'statement',
        },
        {
            title: 'Nodo',
            field: 'node',
        },
        {
            title: 'Estado',
            field: 'status',
            render: rowData => <Badge badgeContent={STATUS[rowData.status]} color={rowData.status==='PUBLISHED'?'primary':'secondary'}/>
        },
        {
            title: 'Tipo',
            field: 'type',
            render : rowData => TYPE_ACTIVITY[rowData.type]
        }
    ]



    handleAddOption = () => {
        const updatedActivity = Object.assign({}, this.state.currentActivity);
        if(updatedActivity.consActivityContent.options.length>=4){
            this.setState({
                error: 'Cada pregunta puede tener máximo 4 opciones posibles'
            })
        }else {
            updatedActivity.consActivityContent.options.push({
                "value": this.possibleOptions[updatedActivity.consActivityContent.options.length],
                "text": ""
            });
            this.setState({
                currentActivity: updatedActivity
            });
        }
    }

    handleEditOption = (event, index) => {
        const updatedActivity = Object.assign({}, this.state.currentActivity);
        updatedActivity.consActivityContent.options[index].text = event.target.value;
        this.setState({
            currentActivity: updatedActivity
        })
    }

    handleEditText = (event)=>{
        const updatedActivity = Object.assign({}, this.state.currentActivity);
        if (event.target.name!=='node' && event.target.name!=='statement') {
            updatedActivity.consActivityContent[event.target.name] = event.target.value;
        } else {
            updatedActivity[event.target.name] = event.target.value;
        }
        this.setState({
            currentActivity: updatedActivity
        });
    }

    handleRemoveOption = (option) => {
        if (!this.isAlreadyChecked(option)) {
            const updatedActivity = Object.assign({}, this.state.currentActivity);
            updatedActivity.consActivityContent.options = updatedActivity.consActivityContent.options
                .filter(element => option.value !== element.value);

            updatedActivity.consActivityContent.options.forEach((element, index)=>{
                element.value=this.possibleOptions[index];
            });
            this.setState({
                currentActivity: updatedActivity
            })
        } else {
            this.setState({
                error: 'No puedes eliminar una respuesta correcta'
            })

        }
    }

    handleClickOpen = () => {
        this.setState({
            isModalOpen: true
        })
    };

    handleNewActivity = () => {
        this.setState({
            currentActivity: {
                node: "",
                statement: "",
                consActivityContent: {
                    options: [],
                    feedbackWrong: "",
                    rightAnswer: [],
                    feedbackCorrect: ""
                },
                experienceId: this.experienceId,
                type: "QUESTION_TEXT"
            }
        })

        this.handleClickOpen();
    }

    handleClose = () => {
        this.setState({
            isModalOpen: false,
            error: null,
        })
    };

    handleSaveActivity = () => {
        if(this.validateActivity()){
            this.activitiesService.saveActivity(this.state.currentActivity).then(response => {
                this.setState({
                    activities: response,
                    isModalOpen: false,
                    error: null,
                })

            }).catch(error => {
                this.setState({
                    error: MESSAGES[error.message],
                })
            })
        }
    }

    handleRemoveActivity = (activity) =>{
                this.activitiesService.removeActivity(activity).then(response => {
            this.setState({
                activities: response
            })
        })
    }

    handleCloseError = () =>{
        this.setState({
            error: undefined
        })
    }

    handleCloseActionError = () =>{
        this.setState({
            actionError: undefined
        })
    }

    handleCheckOption = (event, option) => {
        const updatedActivity = Object.assign({}, this.state.currentActivity);
        if (event.target.checked) {
            updatedActivity.consActivityContent.rightAnswer.push(option.value);
        } else {

            if(updatedActivity.consActivityContent.rightAnswer.length===1){
                this.setState({
                    error: 'Debes tener al menos una respuesta correcta'
                })
            } else {
                updatedActivity.consActivityContent.rightAnswer = updatedActivity.consActivityContent.rightAnswer
                  .filter(element => option.value !== element)
            }
        }

        this.setState({
            currentActivity: updatedActivity
        })
    }

    handlePublishActivity = (activity) => {
        this.activitiesService.publishActivity(activity).then(response => {
            this.setState({
                activities: response,
                error: null,
                actionError:null,
            })
        }, (error)=>{
            console.log(error);
            this.setState({
                actionError: MESSAGES[error.message],
            })
        })
    }

    handleArchiveActivity = (activity) => {
        this.activitiesService.archiveActivity(activity).then(response => {
            this.setState({
                activities: response,
                error: null,
                actionError:null
            })
        })
    }

    validateActivity = () => {
        let errorMessage = null
        if (this.state.currentActivity.consActivityContent.rightAnswer.length === 0) {
            errorMessage = 'Debes seleccionar al menos una respuesta correcta';
        }
        if (this.state.currentActivity.consActivityContent.options.length < 2) {
            errorMessage = 'La pregunta debe tener al menos dos opciones de respuesta';
        }

        const repeatedNodes = this.state.activities.consActivityGroup
          .filter(element => this.state.currentActivity.id !== element.id && this.state.currentActivity.node === element.node && this.state.currentActivity.status==='PUBLISHED').length;
        if (repeatedNodes > 0) {
            errorMessage = 'El nodo que seleccionaste ya tiene una pregunta publicada asociada.';
        }

        if (this.state.currentActivity.consActivityContent.options.filter(option=>!option.text).length>0){
            errorMessage = 'Debes diligenciar un texto en cada una de las opciones de respuesta.';
        }


        if (errorMessage) {
            this.setState({
                error: errorMessage
            })
        }
        return errorMessage === null;

    }

    isAlreadyChecked = (option) => {
        return this.state.currentActivity.consActivityContent.rightAnswer.filter(element => option.value === element).length > 0
    }

    render() {
        const { history } = this.props;

        if (!this.state.activities) return <Loader/>
        return <section className="Home">

            <div className="m-2">
                <div className="container-fluid col-12 col-md-11 table-responsive-md overflow-auto">

                    <Dialog open={this.state.isModalOpen} onClose={this.handleClose} fullWidth="lg" maxWidth="lg">
                        <DialogTitle>Crear o Editar</DialogTitle>
                        {this.state.error?
                          <Alert severity="error" onClose={this.handleCloseError}>
                              <AlertTitle>Error</AlertTitle>
                              {this.state.error} <strong>Corrige antes de guardar!</strong>
                          </Alert>
                          :null}

                        <DialogContent>
                            <DialogContentText>
                                Digite los datos de la actividad de consolidación
                            </DialogContentText>
                            {this.state.currentActivity.consActivityContent && <>
                                <FormControl fullWidth sx={{m: 1}} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-amount">Enunciado</InputLabel>
                                    <Input
                                        value={this.state.currentActivity.statement}
                                        onChange={this.handleEditText}
                                        name="statement"
                                    />
                                </FormControl>

                                <FormControl fullWidth sx={{m: 1}} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-amount">Feedback Positivo</InputLabel>
                                    <Input
                                        value={this.state.currentActivity.consActivityContent.feedbackCorrect}
                                        onChange={this.handleEditText}
                                        name="feedbackCorrect"
                                    />
                                </FormControl>


                                <FormControl fullWidth sx={{m: 1}} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-amount">Feedback Negativo</InputLabel>
                                    <Input
                                        value={this.state.currentActivity.consActivityContent.feedbackWrong}
                                        onChange={this.handleEditText}
                                        name="feedbackWrong"
                                    />
                                </FormControl>

                                <FormControl fullWidth sx={{m: 1}} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-amount">Nodo</InputLabel>
                                    <Input
                                        type="number"
                                        value={this.state.currentActivity.node}
                                        onChange={this.handleEditText}
                                        name="node"
                                    />
                                </FormControl>


                                <Container maxWidth="sm">
                                    <FormGroup>
                                        {
                                            this.state.currentActivity.consActivityContent.options.map(
                                                (option, index) => {
                                                    return (
                                                        <FormControl key={option.value} fullWidth sx={{m: 1}}
                                                                     variant="standard">

                                                            <TextField
                                                                id="input-with-icon-textfield"
                                                                onChange={(event) => {
                                                                    this.handleEditOption(event, index)
                                                                }}
                                                                label={option.value}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Checkbox key={option.value}
                                                                                      onChange={(event) => {
                                                                                          this.handleCheckOption(event, option)
                                                                                      }}
                                                                                      checked={this.isAlreadyChecked(option)}/>
                                                                        </InputAdornment>
                                                                    ),
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <RemoveIcon onClick={() => {
                                                                                this.handleRemoveOption(option)
                                                                            }}/>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                variant="standard"
                                                                value={option.text}
                                                            />
                                                        </FormControl>
                                                    )
                                                }
                                            )
                                        }
                                    </FormGroup>
                                </Container>
                            </>}
                            <Button variant="outlined" startIcon={<AddIcon/>} onClick={this.handleAddOption}>
                                Agregar Opcion
                            </Button>
                        </DialogContent>
                        <Divider/>
                        <DialogActions>
                            <Button onClick={this.handleClose}>Cancelar</Button>
                            <Button onClick={this.handleSaveActivity}>Guardar</Button>
                        </DialogActions>
                    </Dialog>
                        <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained"  onClick={()=>{
                            history.go(-1)
                        }}>
                            <ArrowBackIcon/>
                            Atras
                        </Button>
                        <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained"  onClick={this.handleNewActivity}>
                            Agregar Actividad <WorkIcon/>
                        </Button>
                        {this.state.actionError?
                          <Alert severity="error" onClose={this.handleCloseActionError}>
                              <AlertTitle>Error</AlertTitle>
                              {this.state.actionError} <strong>Revísalo e intenta de nuevo!</strong>
                          </Alert>
                          :null}
                        <MaterialTable
                            columns={this.columns}
                            data={this.state.activities.consActivityGroup}
                            title="Actividades de Consolidación "
                            actions={[
                                rowData=>({
                                    disabled: ['PUBLISHED', 'ARCHIVED'].includes(rowData.status) ,
                                    icon: 'edit',
                                    tooltip: 'Editar',
                                    onClick: (event, data) => {
                                        if (data.status!=='PUBLISHED') {
                                            this.setState({
                                                currentActivity: data
                                            })
                                            this.handleClickOpen()
                                        } else {
                                            alert('No se puede modificar');
                                        }

                                    }
                                }),
                                rowData=>({
                                    disabled: ['PUBLISHED', 'ARCHIVED'].includes(rowData.status),
                                    icon: 'delete',
                                    tooltip: 'Eliminar',
                                    onClick: (event, data) => {
                                        this.handleRemoveActivity(data);
                                    }
                                }),
                                rowData=>({
                                    icon: ()=>{return ['UNPUBLISHED', 'ARCHIVED'].includes(rowData.status)?<UnarchiveIcon color="primary"/>:<ArchiveIcon color="secondary"/>},
                                    tooltip: ['UNPUBLISHED', 'ARCHIVED'].includes(rowData.status)?'Publicar':'Archivar',
                                    onClick: (event, data) => {

                                        if(['UNPUBLISHED', 'ARCHIVED'].includes(rowData.status)){
                                            this.handlePublishActivity(data)
                                        } else {
                                            this.handleArchiveActivity(data);
                                        }
                                    }
                                })
                            ]}

                            options={{
                                actionsColumnIndex: -1,
                                pageSize: 5
                            }}
                            localization={{
                                header: {
                                    actions: "Acciones"
                                },
                                toolbar: {
                                    searchPlaceholder: "Buscar"
                                },
                                pagination: {
                                    labelRowsSelect: "filas",
                                    labelDisplayedRows: "{from}-{to} de {count}"
                                },
                                body: {
                                    emptyDataSourceMessage: "No has creado ninguna actividad de consolidación"
                                }
                            }}
                        />
                </div>
            </div>
        </section>
    }
}

export default withRouter(ListActivities)