import React from "react";
import {withRouter} from "react-router-dom";
import {UserService} from "../../services/UserService";
import Loader from "../../utils/Loader";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Alert, AlertTitle, Avatar, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MaterialTable from "material-table";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import {getRoleFromCode, MESSAGES, ROLE} from "../../utils/Messages";
import {ClientService} from "../../services/ClientService";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import KeyIcon from '@material-ui/icons/VpnKey';
import ContactMailIcon from '@material-ui/icons/ContactMail';

class UserList extends React.Component {

  userService = new UserService();
  clientService = new ClientService();

  constructor(props) {
    super(props);
    this.state = {
      actionError: null,
      users: null,
      isModalOpen: false,
      isModalUsersOpened: false,
      isLoading: false,
      isLoadingModal: false,
      currentUser: {},
      tmpPassword: null
    };
  }

  componentDidMount() {
    Promise.all([this.userService.getUsers({}), this.clientService.getClients()]).then((response) => {
      this.setState({
        users: response[0],
        clients: response[1]
      })
    }).catch(err => {
      console.log("Errrr", err);
    });

  }

  columns = [
    {
      title: 'Correo Electrónico',
      field: 'attributes.email'
    },
    {
      title: 'Nombre',
      field: 'attributes.name'
    },
    {
      title: 'Apellido',
      field: 'attributes.lastName'
    },
    {
      title: 'Cliente',
      field: 'attributes.clientIds',
      render: rowData => this.getClientFromId(rowData.attributes.clientIds)
    },
    {
      title: 'Role',
      field: 'attributes.role',
      render: rowData => getRoleFromCode(rowData.attributes.role)

    }
  ];

  handleClose = () => {

    this.setState({
      isModalOpen: false,
      error: null,
    })
  };

  getClientFromId=(id)=>{
    const foundClient = this.state.clients.filter(client=> client.id===id);
    if(foundClient && foundClient.length>0){
      return foundClient[0].name
    }
    return 'Nova Transmedia (Usuario Global)'
  }

  handleCloseError = () => {
    this.setState({
      error: undefined
    })
  }

  handleSaveUser = () => {
    this.setState({
      isLoadingModal: true
    }, () => {
      this.userService.saveUser(this.state.currentUser).then(response => {

        this.setState({
          isLoadingModal: false,
          error: null,
          tmpPassword: response.password
        })
      }).catch(error => {
        this.setState({
          error: MESSAGES[error.message],
        })
      })
    })
  }

  handleClickOpen = () => {
    this.setState({
      isModalOpen: true
    })
  };

  handleRemoveUser = (user) => {
    this.setState({
      isLoading: true
    }, () => {
      this.userService.removeUser({
        id: user.Username,
        email: user.attributes.email,
        name: user.attributes.name,
        lastName: user.attributes.lastName,
        role: user.attributes.role,
        clientIds: user.attributes.clientIds || ''
      }).then(response => {
        window.location.reload();
      }).catch(error => {
        this.setState({
          isLoading: false,
          actionError: MESSAGES[error.message],
        })
      })
    })
  }

  handleNewClient = () => {
    this.setState({
      currentUser: {
        id:'',
        email: '',
        name: '',
        lastName: '',
        role: ROLE.CLIENT_ADMIN.code,
        clientIds: this.state.clients && this.state.clients.length===1?this.state.clients[0].client:''
      }
    })

    this.handleClickOpen();
  }

  handleEditText = (event) => {
    const updatedUser = Object.assign({}, this.state.currentUser);
    updatedUser[event.target.name] = event.target.value;
    this.setState({
      currentUser: updatedUser
    });
  }


  render() {

    const {history} = this.props;

    if (!this.state.users || this.state.isLoading) return <Loader/>
    return <section className="Home">
      <Dialog open={this.state.isModalOpen} onClose={this.handleClose} fullWidth="lg" maxWidth="lg">
        {this.state.isLoadingModal ? <Loader/> : <>
          <DialogTitle>Crear o Editar</DialogTitle>
          {this.state.error ?
            <Alert severity="error" onClose={this.handleCloseError}>
              <AlertTitle>Error</AlertTitle>
              {this.state.error} <strong>Corrige antes de guardar!</strong>
            </Alert>
            : null}

          {this.state.tmpPassword?<>
            <Alert severity="success">
              <AlertTitle>Operación Existosa</AlertTitle>
              El usuario ha sido creado exitosamente
            </Alert>

            <DialogContent>
              <DialogContentText>
                {this.state.tmpPassword!=='********'?'Por favor copia el password del usuario en un lugar seguro, no podrás consultar esta información posteriormente.':'El usuario ha sido actualizado correctamente'}
              </DialogContentText>


              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={this.state.currentUser.name+' '+this.state.currentUser.lastName} secondary={getRoleFromCode(this.state.currentUser.role)} />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ContactMailIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={this.state.currentUser.email} secondary="Correo para ingreso" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <KeyIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={this.state.tmpPassword} secondary="Copia el password de ingreso" />
                </ListItem>
              </List>

            </DialogContent>

          </>:<DialogContent>
            <DialogContentText>
              Digite los datos del usuario
            </DialogContentText>
            {this.state.currentUser && <>
              <FormControl fullWidth sx={{m: 1}} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">Nombre</InputLabel>
                <Input
                  value={this.state.currentUser.name}
                  onChange={this.handleEditText}
                  name="name"
                />
              </FormControl>
              <FormControl fullWidth sx={{m: 1}} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">Apellido</InputLabel>
                <Input
                  value={this.state.currentUser.lastName}
                  onChange={this.handleEditText}
                  name="lastName"
                />
              </FormControl>
              <FormControl fullWidth sx={{m: 1}} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">Correo Electrónico</InputLabel>
                <Input
                  disabled={this.state.currentUser.id!==null && this.state.currentUser.id!==''}
                  value={this.state.currentUser.email}
                  onChange={this.handleEditText}
                  name="email"
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                <Select
                  name="role"
                  value={this.state.currentUser.role}
                  label="Rol"
                  onChange={this.handleEditText}
                >
                  <MenuItem value={ROLE.CLIENT_ADMIN.code}>{ROLE.CLIENT_ADMIN.name}</MenuItem>
                  <MenuItem value={ROLE.CLIENT_USER.code}>{ROLE.CLIENT_USER.name}</MenuItem>
                  <MenuItem hidden={this.userService.getUser().attributes.role!==ROLE.ADMIN.code} value={ROLE.ADMIN.code}>{ROLE.ADMIN.name}</MenuItem>
                </Select>
              </FormControl>


              {this.state.currentUser.role !== ROLE.ADMIN.code &&  this.state.clients.length>1 ? <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Cliente</InputLabel>
                <Select
                  name="clientIds"
                  value={this.state.currentUser.clientIds}
                  label="Cliente"
                  onChange={this.handleEditText}
                >
                  {this.state.clients.map((item, key) => {
                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                  })}
                </Select>
              </FormControl> : null}

              {this.state.currentUser.role !== ROLE.ADMIN.code && this.state.clients.length===1? <Alert severity="info">
                Cliente asociado <strong>{this.state.clients[0].name}</strong>
              </Alert>:null}
            </>}

          </DialogContent>}
          {this.state.tmpPassword?<DialogActions>
              <Button onClick={()=>{
                this.setState({
                  tmpPassword:null
                })
                window.location.reload();
                }}>Cerrar</Button>
            </DialogActions>:
          <DialogActions>
            <Button onClick={this.handleClose}>Cancelar</Button>
            <Button onClick={this.handleSaveUser}>Guardar</Button>
          </DialogActions>}
        </>}

      </Dialog>

      <div className="m-1">
        <div className="container-fluid col-12 col-md-11 table-responsive-md overflow-auto">
          <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained" onClick={() => {
            history.push('/login')
          }}>
            <ArrowBackIcon/>
            Atras
          </Button>
          <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained" onClick={this.handleNewClient}>
            Crear Usuario.
            <AccountCircleIcon/>

          </Button>
          {this.state.actionError ?
            <Alert severity="error" onClose={this.handleCloseActionError}>
              <AlertTitle>Error</AlertTitle>
              {this.state.actionError} <strong>Revísalo e intenta de nuevo!</strong>
            </Alert>
            : null}

          <div className="m-2">
            <MaterialTable
              columns={this.columns}
              data={this.state.users}
              title="Usuarios"
              actions={[
                {
                  icon: 'edit',
                  tooltip: 'Editar',
                  onClick: (event, data) => {
                    this.setState({
                      currentUser: {
                        id: data.Username,
                        email: data.attributes.email,
                        name: data.attributes.name,
                        lastName: data.attributes.lastName,
                        role: data.attributes.role,
                        clientIds: data.attributes.clientIds || ''
                      }
                    })
                    this.handleClickOpen()
                  }
                },
                rowData=>( {
                  disabled: rowData.Username===this.userService.getUser().attributes.sub,
                  icon: 'delete',
                  tooltip: 'Eliminar',
                  onClick: (event, data) => {
                    this.handleRemoveUser(data);
                  }
                }),

              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 5
              }}
              localization={{
                header: {
                  actions: "Acciones"
                },
                toolbar: {
                  searchPlaceholder: "Buscar"
                },
                pagination: {
                  labelRowsSelect: "filas",
                  labelDisplayedRows: "{from}-{to} de {count}"
                },
                body: {
                  emptyDataSourceMessage: "No has creado ningun usuario"
                }
              }}
            />
          </div>
        </div>
      </div>
    </section>
  }
}

export default withRouter(UserList)