// Home.js
import React from 'react'
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import BusinessIcon from '@material-ui/icons/Business';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {Grid} from "@material-ui/core";
import Container from "@mui/material/Container";
import {Divider} from "@mui/material";
import {Link} from "react-router-dom";
import {Alert} from "@mui/lab";
import ContentRoleLoader from "../utils/ContentRoleLoader";
import {ROLE} from "../utils/Messages";
import MobileIcon from "@material-ui/icons/MobileFriendly";
import PeopleIcon from '@material-ui/icons/People';
class Home extends React.Component {
  clientAdminHome() {
    return <Container style={{marginTop: '5%'}}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Container maxWidth="sm">
            <Link to="/users"><AccountCircleIcon fontSize="large"/> Usuarios Admin</Link>
            <Divider/>
            Administra los usuarios administradores asociados al cliente
          </Container>
        </Grid>
        <Grid item xs={4}>
          <Container maxWidth="sm">
            <Link to="/licensed-users"><MobileIcon fontSize="large"/> Usuarios App</Link>
            <Divider/>
            Administra los usuarios asociados a tu licencia, que pueden usar la app de NOVA360+
          </Container>
        </Grid>
        <Grid item xs={4}>
          <Container maxWidth="sm">
            <Link to="/active-experiences"><ThreeDRotationIcon fontSize="large"/> Activar Experiencias</Link>
            <Divider/>
            Activa e inactiva las experiencias disponibles
          </Container>
        </Grid>
        <Grid item xs={4}>
          <Container maxWidth="sm">
            <Link to="/user-groups"><PeopleIcon fontSize="large"/> Grupos de Usuarios</Link>
            <Divider/>
            Grupos de Usuarios
          </Container>
        </Grid>
      </Grid>

    </Container>
  }

  clientUserHome() {
    return <Container style={{marginTop: '5%'}}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Container maxWidth="sm">
            <Link to="/user-groups"><PeopleIcon fontSize="large"/> Grupos de Usuarios</Link>
            <Divider/>
            Grupos de Usuarios
          </Container>
        </Grid>
      </Grid>
    </Container>
  }

  adminHome() {
    return <Container style={{marginTop: '5%'}}>
      <Grid container spacing={2}>
        <Grid item xs={4}>

          <Container maxWidth="sm">
            <Link to="/experiences"><ThreeDRotationIcon fontSize="large"/> Experiencias</Link>
            <Divider/>
            Administra las experiencias 3D de la aplicación, aquí puedes no solo administrar las experiencias, también
            puedes crear recursos adicionales y administrar las actividades de consolidación de cada experiencia
          </Container>

        </Grid>
        <Grid item xs={4}>
          <Container maxWidth="sm">
            <Link to="/clients"><BusinessIcon fontSize="large"/> Clientes</Link>
            <Divider/>
            Administra los clientes, aquí también puedes administrar la licencia de cada cliente
          </Container>
        </Grid>
        <Grid item xs={4}>
          <Container maxWidth="sm">
            <Link to="/users"><AccountCircleIcon fontSize="large"/> Usuarios</Link>
            <Divider/>
            Administra los usuarios, y los usuarios de los clientes
          </Container>
        </Grid>

      </Grid>
    </Container>
  }

  render() {
    return <section className="Home">
      <Alert severity="info">Bienvenido a NOVA360+, en este panel de administración podrás administrar todas las
        opciones que están disponibles en la App</Alert>
      <ContentRoleLoader userRoles={[ROLE.ADMIN.code]} component={this.adminHome}/>
      <ContentRoleLoader userRoles={[ROLE.CLIENT_ADMIN.code]} component={this.clientAdminHome}/>
      <ContentRoleLoader userRoles={[ROLE.CLIENT_USER.code]} component={this.clientUserHome}/>
    </section>;
  }
}

export default Home;




