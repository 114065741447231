// Home.js
import React from 'react'

const PageNotFound = () => (
  <section className="Home">
    <div className="d-flex justify-content-center">
      <h1>PAGE NOT FOUND</h1>
    </div>
  </section>
)

export default PageNotFound