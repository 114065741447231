import {Redirect} from "react-router-dom";
import useAuth from "../auth/useAuth";

export default function ContentRoleLoader({ component: Component, ...args }) {
  const auth = useAuth();
  console.log(args.userRole, auth.user.attributes.role);
  return (
    <>
     {
        auth.isLogged() ? <> {
          !args.userRoles || args.userRoles.length===0 || args.userRoles.includes(auth.user.attributes.role) ? <Component {...args} /> : null
        }
        </> : <Redirect to="/" />
      }
    </>
  );
}