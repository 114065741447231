// Login.js
import React from 'react'
import AuthService from "../services/AuthService";
import Loader from "../utils/Loader";
import {withRouter} from "react-router-dom";

class Login extends React.Component{

    authService = new AuthService();

    constructor(props) {
        super(props);
        this.state = {
            username: null,
            password: null,
            loading: false
        };
    }

    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.setState({
            loading: true
        }, ()=>{
            this.authService.login(this.state.username, this.state.password).then(response=>{
                this.props.history.go(0);
            })
        })
    }

    render() {

        if (this.state.loading) return <Loader/>
        return <section className="Login">
            <div className="login-container justify-content-center row m-0">
                <div className="col-12 col-sm-7 col-md-5 m-2">
                    <div className="card m-5 card-form">
                        <div className="card-body">
                            <div className="text-center">
                                <h3 className="card-title">Iniciar sesión</h3>
                            </div>
                            <form onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <label>Nombre de usuario</label>
                                    <input type="text" className="form-control input-gray" name="username" placeholder="Usuario"
                                           onChange={this.onChange} required/>
                                </div>
                                <div className="form-group">
                                    <label>Contraseña</label>
                                    <input type="password" className="form-control input-gray" name="password" placeholder="-----"
                                           onChange={this.onChange} required/>
                                </div>
                                <div className="form-group justify-content-center row">
                                    <button type="submit" className="btn btn-green">
                                        Acceder
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}

export default withRouter(Login)