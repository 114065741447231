import React from "react";
import {withRouter} from "react-router-dom";
import {ClientService} from "../../services/ClientService";
import {ExperiencesService} from "../../services/ExperiencesService";
import Loader from "../../utils/Loader";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Alert, AlertTitle} from "@mui/material";
import MaterialTable from "material-table";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

class ClientActiveExperiences extends React.Component {

  clientService = new ClientService();
  experiencesService = new ExperiencesService();

  constructor(props) {

    super(props);
    this.state = {
      experiences: null,
      clients: null
    };
  }


  componentDidMount() {
    Promise.all([
      this.experiencesService.getExperiences(),
      this.clientService.getClients()]
    ).then((response) => {

      const activeExperiences = response[0].filter(item=>{
        return response[1][0].license.enabledExperiences.includes(item.id);
      })

      if(!response[1][0].license.activeExperiences){
        response[1][0].license.activeExperiences=[]
      }
      this.setState({
        experiences: activeExperiences,
        client: response[1][0]
      })
    });
  }

  columns = [
    {
      title: 'ID',
      field: 'id',
    },
    {
      title: 'Nombre',
      field: 'name',
    },
    {
      title: 'Categoria',
      field: 'category',
    },
    {
      title: 'Versión',
      field: 'version',
    }
  ];

  handleActivateExperience (experienceId, action) {

    this.clientService.activateExperiences({
      experienceIds:[experienceId],
      action: action
    }).then(()=>{
      window.location.reload();
    })
  }

  render() {

    const {history} = this.props;

    if (!this.state.experiences || this.state.isLoading) return <Loader/>

    return <section className="Home">
      <div className="m-1">
        <div className="container-fluid col-12 col-md-11 table-responsive-md overflow-auto">
          <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained" onClick={() => {
            history.push('/login')
          }}>
            <ArrowBackIcon/>
            Atras
          </Button>
          {this.state.actionError ?
            <Alert severity="error" onClose={this.handleCloseActionError}>
              <AlertTitle>Error</AlertTitle>
              {this.state.actionError} <strong>Revísalo e intenta de nuevo!</strong>
            </Alert>
            : null}

          <div className="m-2">
            <MaterialTable
              columns={this.columns}
              data={this.state.experiences}
              title="Experiencias"
              actions={[
                rowData=>({
                  icon: ()=>{return this.state.client.license.activeExperiences.includes(rowData.id)?<CheckBoxIcon color="primary"/>:<CheckBoxOutlineBlankIcon color="secondary"/>},
                  tooltip: this.state.client.license.activeExperiences.includes(rowData.id)?'Desactivar':'Activar',
                  onClick: (event, data) => {
                    if(this.state.client.license.activeExperiences.includes(rowData.id)){
                      this.handleActivateExperience(rowData.id, 'DEACTIVATE')
                    } else {
                      this.handleActivateExperience(rowData.id, 'ACTIVATE')
                    }
                  }
                })
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 5
              }}
              localization={{
                header: {
                  actions: "Acciones"
                },
                toolbar: {
                  searchPlaceholder: "Buscar"
                },
                pagination: {
                  labelRowsSelect: "filas",
                  labelDisplayedRows: "{from}-{to} de {count}"
                },
                body: {
                  emptyDataSourceMessage: "No has creado ninguna experiencia"
                }
              }}
            />
          </div>
        </div>
      </div>
    </section>
  }
}

export default withRouter(ClientActiveExperiences)