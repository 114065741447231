// Home.js
import React from 'react'

const Page403 = () => (
  <section className="Home">
    <div className="d-flex justify-content-center">
      <h1>NO ACCESS</h1>
    </div>
  </section>
)

export default Page403