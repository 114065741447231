import {GenericService} from "./GenericService";

export class LicensedUsersService extends GenericService{

  getLicensedUsers = () => {
    return this.consumeService(`/license/licensed-users/admin/get`, 'GET');
  }
  addLicensedUsers = (licensedUsers) =>{
    return this.consumeService('/license/licensed-users/admin/add', 'POST', licensedUsers);
  }

  removeLicensedUser = (user) =>{
    return this.consumeService('/license/licensed-users/admin/remove', 'DELETE', user);
  }

}

