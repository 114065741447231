// Home.js
import React from 'react'
import useFetch from '../useFetch'

const About = () => {


  const { data, loading } = useFetch("https://nl360admin.novatransmedia.com/tokens");

  if (loading) return <h1>Espere...</h1>

  return (
  <section className="Home">
    {console.log(data)}
    <h3>{data?.Count}</h3>
  </section>
  )
}
export default About