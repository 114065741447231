import {BrowserRouter as Router, Switch} from 'react-router-dom';
import PublicRoute from "./PublicRoute";

import Navigation from '../pages/Navigation'


import ActivityList from '../pages/experiences/ActivityList'
import Login from '../pages/Login'

import ExperienceList from '../pages/experiences/ExperienceList'

import About from '../pages/About'
import PageNotFound from '../pages/PageNotFound'
import Page403 from '../pages/Page403'
import Home from '../pages/Home';
import ExperienceAdditionalSources from "../pages/experiences/ExperienceAdditionalSources";
import ClientList from "../pages/license/ClientList";
import UserList from "../pages/users/UserList";
import RoleRoute from "./RoleRoute";
import {ROLE} from "../utils/Messages";
import LicensedUsers from "../pages/license/LicensedUsers";
import ClientActiveExperiences from "../pages/license/ClientActiveExperiences";
import UserGroups from "../pages/license/UserGroups";

export default function AppRouter() {
    return (
        <Router>
            <Navigation />
            <Switch>


                <RoleRoute path="/experiences/activities/:experienceId" roles={[ROLE.ADMIN.code]} exact component={ActivityList} />
                <RoleRoute path="/experiences/activities/:experienceId" roles={[ROLE.ADMIN.code]} exact component={ActivityList} />
                <RoleRoute path="/experiences/additional-sources/:experienceId" roles={[ROLE.ADMIN.code]} exact component={ExperienceAdditionalSources} />
                <RoleRoute path="/experiences" roles={[ROLE.ADMIN.code]} exact component={ExperienceList} />


                <RoleRoute path="/users" roles={[ROLE.ADMIN.code, ROLE.CLIENT_ADMIN.code]} exact component={UserList} />
                <RoleRoute path="/licensed-users" roles={[ROLE.CLIENT_ADMIN.code]} exact component={LicensedUsers} />
                <RoleRoute path="/active-experiences" roles={[ROLE.CLIENT_ADMIN.code]} exact component={ClientActiveExperiences} />
                <RoleRoute path="/user-groups" roles={[ROLE.CLIENT_USER.code, ROLE.CLIENT_ADMIN.code]} exact component={UserGroups} />
                <RoleRoute path="/" exact component={Home} />

                <RoleRoute path="/clients" roles={[ROLE.ADMIN.code]}  exact component={ClientList} />

                <PublicRoute exact path="/login" component={Login} />

                <RoleRoute exact path="/about" component={About} />
                <RoleRoute exact path="/403" component={Page403} />
                <RoleRoute component={PageNotFound} />

            </Switch>
        </Router >
    )
}