import React from "react";
import {withRouter} from "react-router-dom";
import {UserGroupsService} from "../../services/UserGroupsService";
import Loader from "../../utils/Loader";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import {
  Alert,
  AlertTitle,
  Checkbox,
  Dialog,
  DialogContent, FormControlLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import MaterialTable from "material-table";
import PeopleIcon from '@material-ui/icons/People';
import {MESSAGES} from "../../utils/Messages";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import DialogActions from "@mui/material/DialogActions";
import {ExperiencesService} from "../../services/ExperiencesService";
import {LicensedUsersService} from "../../services/LicensedUsersService";

class UserGroups extends React.Component {

  userGroupsService = new UserGroupsService();
  experiencesService = new ExperiencesService();
  licensedUsersService = new LicensedUsersService();

  constructor(props) {
    super(props);
    this.state = {
      currentUserGroup: null,
      actionError: null,
      userGroups: null,
      isModalOpen: false,
      isLoading: false,
      isLoadingModal: false,
      experiences: null,
      availableUsers: null,
      showJustGroupMembers: false
    };
  }

  columns = [
    {
      title: 'Nombre',
      field: 'name',
    },
    {
      title: 'Experiencias',
      field: 'experiences',
      render: rowData => {
        let experienceNames='';
        if (!rowData.experiences) {
          return '';
        }
        rowData.experiences.forEach(item=>{
          const foundExperiences = this.state.experiences.filter(exp => exp.id===item);
          if(foundExperiences && foundExperiences.length>0){
            experienceNames = `${experienceNames}${experienceNames!==''?', ':''}${foundExperiences[0].name}`
          }

        })
        return experienceNames
      }
    },
  ];


  ITEM_HEIGHT = 48;
  ITEM_PADDING_TOP = 8;
  menuProps = {
    PaperProps: {
      style: {
        maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
        width: 500,
      },
    },
  };


  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    Promise.all([
      this.userGroupsService.getUserGroups(),
      this.experiencesService.getExperiences(),
      this.licensedUsersService.getLicensedUsers()]
    ).then((response) => {
      this.setState({
        userGroups: response[0],
        experiences: response[1],
        availableUsers: response[2],
        isLoading: false,
      })
    });
  }

  handleNewUserGroup = () => {
    this.setState({
      currentUserGroup: {
        name: '',
        users: [],
        experiences: []
      }
    })

    this.handleClickOpen();
  }

  handleCloseActionError = () => {
    this.setState({
      actionError: undefined
    })
  }

  handleClickOpen = () => {
    this.setState({
      isLoadingModal: true,
      isModalOpen: true
    });
    if (!this.state.experiences || this.state.experiences.length === 0) {
      this.experiencesService.getExperiences().then(response => {
        this.setState({
          isLoadingModal: false,
          experiences: response
        })
      }).catch(err => {
        this.setState({
          isLoadingModal: false
        });
      });
    } else {
      this.setState({
        isLoadingModal: false
      })
    }

  };

  handleRemoveUserGroup = (userGroup) => {
    this.setState({
      isLoading: true
    }, () => {
      this.userGroupsService.removeUserGroup(userGroup).then(response => {
        this.setState({
          userGroups: response,
          isModalOpen: false,
          isLoading: false,
          error: null,
        })
      }).catch(error => {
        this.setState({
          isLoading: false,
          actionError: MESSAGES[error.message],
        })
      })
    })
  }

  handleEditText = (event) => {
    const updatedUserGroup = Object.assign({}, this.state.currentUserGroup);
    updatedUserGroup[event.target.name] = event.target.value;
    this.setState({
      currentUserGroup: updatedUserGroup
    });
  }

  handleClose = () => {
    this.setState({
      isModalOpen: false,
      error: null,
    })
  };

  handleSaveUserGroup = () => {
    this.setState({
      isLoadingModal: true
    }, () => {
      this.userGroupsService.saveUserGroup(this.state.currentUserGroup).then(response => {
        this.setState({
          userGroups: response,
          isModalOpen: false,
          isLoadingModal: false,
          error: null,
        })
      }).catch(error => {
        this.setState({
          error: MESSAGES[error.message],
        })
      })
    })
  }

  handleChangeExperiences = (event) => {
    const {
      target: {value},
    } = event;

    this.setState(prevState => {
      const updatedUserGroup = Object.assign({}, prevState.currentUserGroup);
      updatedUserGroup[event.target.name] = event.target.value;
      updatedUserGroup.experiences = typeof value === 'string' ? value.split(',') : value
      return {currentUserGroup: updatedUserGroup}
    })
  };

  handleAddUserToUserGroup = (user)=>{
    this.setState({
      isLoadingModal: true
    }, () => {
      this.userGroupsService.addUserToGroup({
        userGroupId: this.state.currentUserGroup.id,
        email: user.email
      }).then(response => {
        this.setState(prevState => {
          const updatedUserGroup = Object.assign({}, prevState.currentUserGroup);
          const updatedUserGroups = Object.assign([], prevState.userGroups);
          updatedUserGroups.forEach(item=>{
            if(item.id===updatedUserGroup.id){
              item.users = response;
            }
          })
          updatedUserGroup.users = response
          return {currentUserGroup: updatedUserGroup, userGroups:updatedUserGroups}
        })
        this.setState({
          isLoadingModal: false,
          error: null,
        })
      }).catch(error => {
        this.setState({
          isLoading: false,
          actionError: MESSAGES[error.message],
        })
      })
    })
  }
  handleShowJustMembers =(data)=>{
    this.setState({
      showJustGroupMembers: data.target.checked,
    })

  }
  handleRemoveUserFromUserGroup = (user)=>{
    this.setState({
      isLoadingModal: true
    }, () => {
      this.userGroupsService.removeUserFromGroup({
        userGroupId: this.state.currentUserGroup.id,
        email: user.email
      }).then(response => {
        this.setState(prevState => {
          const updatedUserGroup = Object.assign({}, prevState.currentUserGroup);
          const updatedUserGroups = Object.assign([], prevState.userGroups);
          updatedUserGroups.forEach(item=>{
            if(item.id===updatedUserGroup.id){
              item.users = response;
            }
          })
          updatedUserGroup.users = response
          return {currentUserGroup: updatedUserGroup, userGroups:updatedUserGroups}
        })
        this.setState({
          isLoadingModal: false,
          error: null,
        })
      }).catch(error => {
        this.setState({
          isLoading: false,
          actionError: MESSAGES[error.message],
        })
      })
    })
  }

  render() {
    const {history} = this.props;

    if (!this.state.userGroups || this.state.isLoading) return <Loader/>

    return <section className="Home">
      <Dialog open={this.state.isModalOpen} onClose={this.handleClose} fullWidth="lg" maxWidth="lg">
        {this.state.isLoadingModal ? <Loader/> : <>
          <DialogTitle>Crear o Editar</DialogTitle>
          {this.state.error ?
            <Alert severity="error" onClose={this.handleCloseError}>
              <AlertTitle>Error</AlertTitle>
              {this.state.error} <strong>Corrige antes de guardar!</strong>
            </Alert>
            : null}

          <DialogContent>
            <DialogContentText>
              Creación de nuevo grupo de usuarios
            </DialogContentText>
            {this.state.currentUserGroup && <>
              <FormControl fullWidth sx={{m: 1}} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">Nombre</InputLabel>
                <Input
                  value={this.state.currentUserGroup.name}
                  onChange={this.handleEditText}
                  name="name"
                  disabled={this.state.currentUserGroup.id != null}
                />
              </FormControl>

              <FormControl sx={{m: 1, width: '100%'}}>
                <InputLabel id="demo-multiple-checkbox-label">Experiencias habilitadas</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={this.state.currentUserGroup.experiences}
                  onChange={this.handleChangeExperiences}
                  input={<OutlinedInput label="Experiencias asignadas"/>}
                  renderValue={(selected) => {
                    return this.state.experiences.filter(item => selected.includes(item.id)).map(item => item.name).join(', ')
                  }}
                  MenuProps={this.menuProps}
                >
                  {this.state.experiences.map((experience) => (
                    <MenuItem key={experience.id} value={experience.id}>
                      <Checkbox checked={this.state.currentUserGroup.experiences.indexOf(experience.id) > -1}/>
                      <ListItemText primary={experience.name}/>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              { this.state.currentUserGroup.id != null?
                <>
                  <FormControlLabel
                    label="Solo ver miembros del grupo"
                    control={
                      <Checkbox
                        checked={this.state.showJustGroupMembers}
                        onChange={this.handleShowJustMembers}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                  />

                  <MaterialTable
                    columns={[
                      {
                        title: 'Correo',
                        field: 'email',
                      },
                      {
                        title: 'Categoria 1',
                        field: 'category1',
                      },
                      {
                        title: 'Categoria 2',
                        field: 'category2',
                      },
                      {
                        title: 'Categoria 3',
                        field: 'category3',
                      },
                      {
                        title: 'Categoria 4',
                        field: 'category4',
                      },
                      {
                        title: 'Categoria 5',
                        field: 'category5',
                      }
                    ]}
                    data={this.state.showJustGroupMembers?this.state.availableUsers.filter(item=>this.state.currentUserGroup.users.includes(item.email)):this.state.availableUsers}
                    //data={this.state.currentUserGroup.users.map(item=>{ return {email:item} })}
                    title="Usuarios del grupo"
                    actions={[
                      rowData=>({
                        icon: this.state.currentUserGroup.users.includes(rowData.email)?'delete':'add',
                        tooltip: this.state.currentUserGroup.users.includes(rowData.email)?'Eliminar del grupo':'Agregar del grupo',
                        onClick: (event, data) => {
                          if(this.state.currentUserGroup.users.includes(rowData.email)){
                            this.handleRemoveUserFromUserGroup(data);
                          } else {
                            this.handleAddUserToUserGroup(data);
                          }
                        }
                      })
                    ]}
                    options={{
                      rowStyle: rowData => ({
                        backgroundColor: this.state.currentUserGroup.users.includes(rowData.email) ? '#80bb7c' : '#ffffff'
                      })
                    }}
                  />
                </>
                :null
              }


            </>}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancelar</Button>
            <Button onClick={this.handleSaveUserGroup}>Guardar</Button>
          </DialogActions>
        </>}
      </Dialog>
      <div className="m-1">
        <div className="container-fluid col-12 col-md-11 table-responsive-md overflow-auto">
          <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained" onClick={() => {
            history.push('/login')
          }}>
            <ArrowBackIcon/>
            Atras
          </Button>
          <Button className="btn btn-green m-3 pr-4 pl-4" variant="contained" onClick={this.handleNewUserGroup}>
            Crear Grupo de Usuarios.
            <PeopleIcon/>
          </Button>
          {this.state.actionError ?
            <Alert severity="error" onClose={this.handleCloseActionError}>
              <AlertTitle>Error</AlertTitle>
              {this.state.actionError} <strong>Revísalo e intenta de nuevo!</strong>
            </Alert>
            : null}

          <div className="m-2">
            <MaterialTable
              columns={this.columns}
              data={this.state.userGroups}
              title="Grupos de Usuario"
              actions={[
                {
                  icon: 'edit',
                  tooltip: 'Editar',
                  onClick: (event, data) => {
                    const updatedUserGroup = Object.assign({}, data);
                    if (!updatedUserGroup.experiences) {
                      updatedUserGroup.experiences = [];
                    }
                    this.setState({
                      currentUserGroup: updatedUserGroup
                    })
                    this.handleClickOpen()
                  }
                },
                {
                  icon: 'delete',
                  tooltip: 'Eliminar',
                  onClick: (event, data) => {
                    this.handleRemoveUserGroup(data);
                  }
                },
                {
                  icon: 'work',
                  tooltip: 'Actividades de consolidación',
                  onClick: (event, data) => {
                    alert("Fake");
                  }
                }
              ]}
              options={{
                actionsColumnIndex: -1,
                pageSize: 5
              }}
              localization={{
                header: {
                  actions: "Acciones"
                },
                toolbar: {
                  searchPlaceholder: "Buscar"
                },
                pagination: {
                  labelRowsSelect: "filas",
                  labelDisplayedRows: "{from}-{to} de {count}"
                },
                body: {
                  emptyDataSourceMessage: "No has creado ningun grupo aún"
                }
              }}
            />
          </div>
        </div>
      </div>
    </section>
  }
}

export default withRouter(UserGroups)