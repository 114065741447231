import {GenericService} from "./GenericService";

export class ActivitiesService extends GenericService{

    getActivities = (experienceId) => {
      return this.consumeService(`/experience/consolidation/admin/get/${experienceId}`, 'GET');
    }
    saveActivity = (activity) =>{
      return this.consumeService('/experience/consolidation/admin/create', 'POST', activity);
   }

   publishActivity = (activity) =>{
     return this.consumeService('/experience/consolidation/admin/publish', 'POST', activity);
   }

  archiveActivity = (activity) =>{
    return this.consumeService('/experience/consolidation/admin/archive', 'POST', activity);
  }

   removeActivity = (activity) =>{
      return this.consumeService('/experience/consolidation/admin/remove', 'DELETE', activity);
   }

}