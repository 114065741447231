import { useEffect, useState } from "react";
import useAuth from './auth/useAuth'

import axios from "axios";

function useFetch(url, params, method='GET') {
    const [data, setData] = useState([]);


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const auth = useAuth();
    useEffect(() => {
        const config = {}
        setLoading(true);
        if(auth.user && auth.user.token){
            config.headers = {
                'Authorization': auth.user.token
            }
        }
     

        if(method==='POST'){
            axios
            .post(url, params, config)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                setError(error);
                console.log("ERROR: " + error)
            })
            .finally(() => {
                setLoading(false);
            })
        }else{
            axios
            .get(url, config)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                setError(error);
                if(error.request){
                    auth.logout();       
                }
                console.log("ERROR: ", error)
            })
            .finally(() => {
                setLoading(false);
            })
        }
        
        
    }, [url]);

    return {data, loading, error};
}



export default useFetch;