import {GenericService} from "./GenericService";

export class UserService extends GenericService{

  getUsers = (filters) => {
    return this.consumeService(`/license/admin-users/admin/list`, 'POST', filters);
  }
  saveUser = (user) =>{
    return this.consumeService('/license/admin-users/admin/add', 'POST', user);
  }

  removeUser = (user) =>{
    return this.consumeService('/license/admin-users/admin/remove', 'DELETE', user);
  }


}