import {GenericService} from "./GenericService";

export class AuthService extends GenericService{

    logout = ()=> {
        localStorage.removeItem("user");
    }

    login = (username, password) =>{
        return new Promise((resolve, reject)=>{
            this.consumeService('/security/admin/login', 'POST', {
                username: username,
                password: password
            }).then(response=>{
                localStorage.setItem("user", JSON.stringify(response))
                resolve(response);
            }).catch(error=>{
                reject(error)
            });
        })
    }

}


export default AuthService;