import {GenericService} from "./GenericService";

export class ExperiencesService extends GenericService{

  getExperiences = () => {
    return this.consumeService(`/experience/admin/list`, 'GET');
  }

  saveExperience = (experience) =>{
    return this.consumeService('/experience/admin/create', 'POST', experience);
  }

  removeExperience = (experience) =>{
    return this.consumeService('/experience/admin/remove', 'DELETE', experience);
  }
}