// App.js
import React from 'react';
import './App.css';
import AuthProvider from "./auth/AuthProvider";
import AppRouter from "./routers/AppRouter";
import {ThemeProvider} from '@mui/material/styles';
import {THEME} from "./utils/Styling";
import PubSub from 'pubsub-js';
import AuthService from "./services/AuthService";

class App extends React.Component {

  authService = new AuthService();

  componentDidMount() {
    PubSub.subscribe('logout', this.logout.bind(this));
  }

  logout(){
    this.authService.logout();
    window.location.href='/login'

  }

  render() {
    return (<ThemeProvider theme={THEME}>
      <div className="App">
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </div>
    </ThemeProvider>)
  }
}

export default App;
