export const MESSAGES = {
  N360EXP01: 'La actividad de consolidación no ha sido encontrada',
  N360EXP02: 'No se ha recibido la respuesta',
  N360EXP03: 'La respuesta no es valida',
  N360EXP04: 'Recuerda que debes diligenciar todos los campos',
  N360EXP05: 'El nodo ya tiene una pregunta publicada asociada',
  N360EXP06: 'La respuesta correcta no está dentro de las opciones que has configurado',
  N360EXP07: 'La actividad ya ha sido publicada y no se puede modificar, debes archivarla y crear una nueva',
  N360EXP08: 'La actividad no puede ser eliminada debido a que ya se encuetra publicada',
  N360EXP09: 'No es posible eliminar la experiencia dado que tiene dependencia con otros recursos del sistema',
  N360LIC02: 'No es posible eliminar la usuario dado que no existe o no tiene permisos para la acción',
  N360LIC03: 'LIMITE EXCEDIDO: Puedes subir hasta un máximo de 200 usuarios por cada carga de excel',
  N360LIC04: 'LIMITE EXCEDIDO: La licencia asociada al cliente supera el límite',
  N360EXP10: 'El nombre del archivo debe ser máximo de 70 caracteres',
  N360EXP11: 'El máximo de recursos para una experiencia es de 10',
  N360EXP12: 'El máximo tamaño de archivo es de 10MB',
  N360EXP13: 'Los links que incluyas deben ser seguros (https)',

}

export const STATUS = {
  PUBLISHED: 'Publicado',
  UNPUBLISHED: 'Borrador',
  ARCHIVED: 'Archivado'
}

export const TYPE_ACTIVITY = {
  QUESTION_TEXT: 'Pregunta',
}

export const ADDITIONAL_SOURCE_TYPE = {
  FILE: 'Archivo',
  VIDEO: 'Video',
  IMAGE: 'Imágen',
  LINK: 'Link'
}


export const LICENSE_TYPE = {
  USER_LIMIT: 'Limite de usuarios',
  USER_UNLIMITED: 'Sin limite de usuarios',
}


export const ROLE = {
  ADMIN: {name: 'Super Administrador', code: 'admin'},
  CLIENT_ADMIN: {name: 'Administrador Cliente', code: 'client-admin'},
  CLIENT_USER: {name: 'Usuario Secundario', code: 'client-user'}
}

export const getRoleFromCode = (roleCode) => {
  if (roleCode) {
    return ROLE[Object.keys(ROLE).filter(role => roleCode === ROLE[role].code)].name
  } else {
    return 'Sin identificar'
  }
}

