// Home.js
import React from 'react'
import {
    Link
} from 'react-router-dom';
import useAuth from '../auth/useAuth'

//Images
import logo1 from '../resources/images/logo1.png';
import logo2 from '../resources/images/logo2.png';
import home from '../resources/images/home.png';
import menu from '../resources/images/menu.png';
import userLogo from '../resources/images/user-logo.png';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import BusinessIcon from '@material-ui/icons/Business';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import PeopleIcon from '@material-ui/icons/People';
import ContentRoleLoader from "../utils/ContentRoleLoader";
import {ROLE} from "../utils/Messages";
import MobileIcon from "@material-ui/icons/MobileFriendly";
const Navigation = () => {
    const auth = useAuth();

    return (
        <section className="Navigation">
            <nav className="container-fluid p-0">
                <div className="logo-container p-3 row m-0">
                    <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start">
                        {!auth.isLogged() &&
                            <img className="p-3" src={logo1} alt="Nova transmedia" />
                        }
                        <img className="p-3" src={logo2} alt="Nova transmedia" />
                    </div>
                    <div className="row col-12 col-md-6 d-flex m-0 pr-md-2 justify-content-md-end justify-content-center">
                        {auth.isLogged() &&
                            <>
                                <div className="d-flex justify-content-center col-6 col-md-4 col-lg-6 pt-3 pb-3">
                                    <button className="btn info-user pr-4 pl-4" id="dropdownMenuLogin" data-toggle="dropdown" aria-expanded="false">
                                        <img className="" src={userLogo} alt="Botón para cerrar sesión" />
                                        {auth.user.username}
                                    </button>
                                    <div className="dropdown-menu p-2 pt-4 pb-4" aria-labelledby="dropdownMenuLogin">
                                        <div className="d-flex justify-content-center">
                                            <button type="button" className="btn btn-dark btn-item " onClick={auth.logout}>
                                                Cerrar sesión <LogoutIcon/>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <Link to="/" className="d-flex justify-content-center col-3 col-md-4 col-lg-3 col-xl-2 ">
                                    <img className="p-2" src={home} alt="Nova transmedia" />
                                </Link>
                            </>
                        }
                        {auth.isLogged() ?
                            <div>
                                <button className="navbar-toggler p-0 col-3 col-md-4 col-lg-3 col-xl-2" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img className="p-2" src={menu} alt="Nova transmedia" />
                                </button>
                                <div className="dropdown-menu p-2 pt-4 pb-4" aria-labelledby="dropdownMenu2">

                                    <ul className="navbar-nav ml-auto">
                                        <ContentRoleLoader userRoles={[ROLE.ADMIN.code]} component={()=>{
                                            return <li className="nav-item">
                                                <div className="d-flex justify-content-center">
                                                    <Link to="/experiences" type="button" className="m-1 btn btn-green btn-item " >
                                                        <ThreeDRotationIcon/> Experiencias
                                                    </Link>
                                                </div>
                                            </li>
                                        }}   />

                                        <ContentRoleLoader userRoles={[ROLE.ADMIN.code]} component={()=>{
                                            return <li className="nav-item">
                                                <div className="d-flex justify-content-center">
                                                    <Link to="/clients" type="button" className="m-1 btn btn-green btn-item " >
                                                        <BusinessIcon/> Clientes
                                                    </Link>
                                                </div>
                                            </li>
                                        }} />

                                        <ContentRoleLoader userRoles={[ROLE.ADMIN.code, ROLE.CLIENT_ADMIN.code]} component={()=>{
                                            return  <li className="nav-item">
                                                <div className="d-flex justify-content-center">
                                                    <Link to="/users" type="button" className="m-1 btn btn-green btn-item " >
                                                        <AccountCircleIcon/> Usuarios
                                                    </Link>
                                                </div>
                                            </li>
                                        }} />

                                        <ContentRoleLoader userRoles={[ROLE.CLIENT_ADMIN.code]} component={()=>{
                                            return  <li className="nav-item">
                                                <div className="d-flex justify-content-center">
                                                    <Link to="/licensed-users" type="button" className="m-1 btn btn-green btn-item " >
                                                        <MobileIcon/> Usuario de la App
                                                    </Link>
                                                </div>
                                            </li>
                                        }} />


                                        <ContentRoleLoader userRoles={[ROLE.CLIENT_ADMIN.code]} component={()=>{
                                            return  <li className="nav-item">
                                                <div className="d-flex justify-content-center">
                                                    <Link to="/active-experiences" type="button" className="m-1 btn btn-green btn-item " >
                                                        <ThreeDRotationIcon/> Activar experiencias
                                                    </Link>
                                                </div>
                                            </li>
                                        }} />
                                        <ContentRoleLoader userRoles={[ROLE.CLIENT_ADMIN.code, ROLE.CLIENT_USER.code]} component={()=>{
                                            return  <li className="nav-item">
                                                <div className="d-flex justify-content-center">
                                                    <Link to="/user-groups" type="button" className="m-1 btn btn-green btn-item " >
                                                        <PeopleIcon/> Grupos de Usuarios
                                                    </Link>
                                                </div>
                                            </li>
                                        }} />



                                    </ul>



                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
                <div className="collapse navbar-collapse" id="navbarNav">
                    {auth.isLogged() &&
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to="/clientes">
                                    Clientes
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/usuarios">
                                    Usuarios
                                </Link>
                            </li>
                            <li className="nav-item">
                                <p className="nav-link" onClick={auth.logout}>Cerrar sesión</p>
                            </li>
                        </ul>
                    }
                    {!auth.isLogged() &&
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/ingreso">Iniciar sesión</a>
                            </li>
                        </ul>
                    }
                </div>
                <div className="green-container">
                    <Link className="nav-title ml-5" to="/">Administración NovaLabs 360+</Link>
                </div>
            </nav>
        </section >
    )
}

export default Navigation