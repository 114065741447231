import { Route, Redirect } from "react-router-dom";
import useAuth from "../auth/useAuth";

export default function RoleRoute({ component: Component, ...args }) {
  const auth = useAuth();
  return (
    <Route {...args}>

      {
        auth.isLogged() ? <> {
          !args.roles || args.roles.length===0 || args.roles.includes(auth.user.attributes.role)? <Component {...args} /> : <Redirect to="/403" />
        }
        </> : <Redirect to="/login" />
      }
    </Route>
  );
}